import analyticsState from "@/store/modules/analytics/state"
import reportState from "@/store/modules/report/state"

export const formatHash = hash => {
	let startSubStr = hash.substring(0,7)
	let endSubStr = hash.slice(-7)

	return `${startSubStr}...${endSubStr}`
}

export const getDecimalVal = (addressData, amount) => {
	const defDecimal = analyticsState?.coinData?.decimals || 0
	const decimal = addressData
		&& addressData.contractData
		&& (addressData.contractData.decimals || 0)

	return Number(amount) / Math.pow(10, decimal ?? defDecimal)
}

export const formatAmount = (amount, withBtc = true, searchType = '', hasContracts = false, needDecimal = false, defFixedVal = 8) => {
	let contract = ''
	let del = 100000000
	let defDecimal = Math.pow(10, !needDecimal ? reportState?.activeToken?.decimals || analyticsState?.coinData?.decimals || 0 : defFixedVal)
	const decimalAfterPoint = convertToDecimalZeros(defFixedVal)
	
	const decimalsMap = {
		'trx': 1000000,
		'eth': 1000000000000000000,
		'btc': 1000000000000000000,
	}

	if (analyticsState.coinData.family === 'eth' && withBtc != 'clearBtc') {
		del = needDecimal ? decimalsMap[searchType] || defDecimal : 1

		if(reportState?.activeToken?.decimals && !needDecimal) {
			del = Math.pow(10, reportState?.activeToken?.decimals)
		}

		// Contract
		if (hasContracts) {
			contract = hasContracts
		} else {
			contract = analyticsState.coinData.symbol
		}

		if (amount < 0.00000001 && !hasContracts) {
			contract = 'wei'
			defFixedVal = 0
			amount = amount * defDecimal
		}
	}

	if(withBtc == 'clearFamily') {
		contract = analyticsState.coinData.family && analyticsState.coinData.family.toUpperCase()
		del = Math.pow(10, 8)
	} else {
		if (!searchType) contract = reportState.activeToken.symbol && reportState.activeToken.symbol.toUpperCase() || analyticsState.coinData.key.toUpperCase() || 'BTC'
		if (!contract) contract = reportState.activeToken.symbol && reportState.activeToken.symbol.toUpperCase() || analyticsState.coinData.key.toUpperCase()
	}

	const formattedAmount = (fAmount, fDel) => {
		// Temp solution for test
		return Math.abs(fAmount) <  decimalAfterPoint || (Math.abs(fAmount) / fDel) < decimalAfterPoint ? `< ${decimalAfterPoint.toFixed(8)}` : truncateToEight((fAmount / fDel))
		// return Math.abs(fAmount) < 0.00000001 ? (fAmount / fDel).toFixed(Math.floor(Math.abs(Math.log10(fAmount)))).replace(/\.?0+$/, "") : (fAmount / fDel).toFixed(fixedVal)
	}

	function truncateToEight(number) {
    return convertIfExponential(parseFloat(number.toFixed(8)))
	}

	function convertIfExponential(number) {
    let str = number.toString()
    
    if (str.indexOf('e') !== -1) {
        return number.toFixed(20).replace(/\.?0+$/, '')
    } else {
        return str
    }
	}

	// Temp solution until tomorrow
	if(withBtc == 'justAddBtc') {
		return `${truncateToEight(amount)} ${contract}`
	}
	if(withBtc == 'clearFamily') {
		return `${formattedAmount(amount, del)} ${contract}`
	}
	return amount
		? `${analyticsState.coinData.family === 'eth'
			? formattedAmount(amount, del)
			: formattedAmount(amount, del)} ${withBtc
			? `${contract}`
			: ''}`
		: analyticsState.coinData.family === 'eth'
			? ''
			: 0
}
export function currencyShareFormatting (amount, decimals) {
	return amount ? Number(amount) * Math.pow(10, decimals ?? analyticsState?.coinData?.decimals ?? 1) : 0
}

export function convertToDecimalZeros(zerosAfterDecimal) {
    var divisor = Math.pow(10, zerosAfterDecimal);
    return  1 / divisor;
}

export const txsAddPlus = (data, threshold = 1000001) => {
  const firstDigit = data.toString().charAt(0)
  return data >= threshold ? `${+firstDigit}M+` : data
}