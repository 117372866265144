<template>
  <div :class="classesForBadgeInfo">
    <div class="badge-info__text ellipsis">
      {{ text }}
    </div>
    <div 
      v-if="isValuePresent" 
      class="badge-info__value"
    >
      {{ calculateRiskLevel(value) }}
    </div>
  </div>
</template>

<script>
import { calculateRiskLevel } from '@/utils/redesign-report'

export default {
  props: {
    text: {
      type: String,
      default: 'Not Identified',
    },
    value: {
      type: [String, Number],
      default: 0,
    },
  },
  computed: {
    isValuePresent() {
      return this.value !== null && this.value !== undefined && (typeof this.value !== 'string' || this.value.trim() !== '')
    },
    classesForBadgeInfo() {
      let classes = [`badge-info`]

      classes.push(`badge-info--${this.calculateRiskLevel(this.value)}`)

      return classes
    }
  },
  methods: {
    calculateRiskLevel
  },
}
</script>

<style scoped>
.badge-info {
  display: inline-flex;
  align-items: center;
  gap: 4px;
  min-height: 24px;
  padding: 2px 8px;
  background-color: rgba(224, 224, 224, 1);
  border-radius: 15px;
}
.badge-info__text {
  font-weight: 500;
  font-size: 14px;
  line-height: 1.2;
  color: var(--black);
  text-transform: capitalize;
  position: relative;
  z-index: 2;
}
.badge-info__value {
  margin-right: -6px;
  padding: 4px 8px;
  border-radius: 10px;
  font-weight: 600;
  font-size: 12px;
  line-height: 1;
  color: var(--white);
  text-transform: uppercase;
  position: relative;
  z-index: 2;
}
.badge-info--low {
  background-color: rgba(112, 172, 63, 0.25);
}
.badge-info--low .badge-info__value {
  background-color: rgba(112, 172, 63, 1);
}
.badge-info--medium {
  background-color: rgba(252, 214, 48, 0.25);
}
.badge-info--medium .badge-info__value {
  background-color: rgba(252, 214, 48, 1);
  color: var(--black);
}
.badge-info--high {
  background-color: rgba(242, 35, 92, 0.25);
}
.badge-info--high .badge-info__value {
  background-color: rgba(242, 35, 92, 1);
}
</style>