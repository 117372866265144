<template>
  <div
    class="gl-scoring-bar-chart-wrapper"
    :class="{ 'demo-for-landing' : demoForLanding}"
  >
    <transition-group
      name="trans-for-bars"
      tag="div"
    >
      <div
        v-for="(item, index) in resultData"
        :key="index"
        :class="classesForBarChartRow(item.score)"
        @click="barClick(item)"
      >
        <div
          class="gl-scoring-bar-chart-row"
          :class="{
            'gl-scoring-bar-chart-row--active' : item.name === activeBarItem.name,
          }"
        >
          <div
            v-if="isMobile"
            class="flex space-between gap-2"
          >
            <div class="gl-scoring-bar-chart-cell-name">
              <BadgeInfo
                :text="item.name"
                :value="item.score"
              />
            </div>
            <div
              class="gl-scoring-bar-chart-cell-amount-wrapper fs-12 bold break-word mt-1 m-fs-10"
            >
              <div class="gl-scoring-bar-chart-cell-amount">
                <div>
                  {{ dataForAmount(item) }}
                </div>
              </div>

              <div class="gl-scoring-bar-chart-cell-amount">
                <div class="fs-10 price m-fs-8">
                  {{ dataForPrice(item) }}
                </div>
              </div>
            </div>
          </div>
          <div class="gl-scoring-bar-chart-bar-wrapper">
            <div
              class="type-entity-label-wrapper"
              :style="{
                minWidth: `${getLabelBarWidth(
                  item.share,
                  basisGraphWidth,
                  basisPercentGraphWidth,
                )}`,
                maxWidth: `${getLabelBarWidth(
                  item.share,
                  basisGraphWidth,
                  basisPercentGraphWidth,
                )}`,
              }"
            >
              <div
                v-if="!isMobile"
                class="gl-scoring-bar-chart-cell-name"
              >
                <BadgeInfo
                  :text="item.name"
                  :value="item.score"
                />
              </div>
              <div
                v-if="!isMobile"
                class="gl-scoring-bar-chart-cell-amount-wrapper fs-12 bold break-word ma-2 m-fs-10"
              >
                <div class="gl-scoring-bar-chart-cell-amount">
                  <div>
                    {{ dataForAmount(item) }}
                  </div>
                </div>

                <div class="gl-scoring-bar-chart-cell-amount">
                  <div class="fs-10 price m-fs-8">
                    {{ dataForPrice(item) }}
                  </div>
                </div>
              </div>
            </div>
            <div
              v-tooltip="{
                content: `indirect`,
                show: hoveredIndirectTooltipItem === index,
                trigger: 'manual',
                boundariesElement: 'body',
              }"
              class="type-entity-bar-indirect"
              :style="{
                minWidth: `${getProgressBarIndirectWidth(
                  item.share,
                  item.deepDepthShare,
                  item.generalShare,
                  basisGraphWidth,
                  basisPercentGraphWidth,
                )}`,
                maxWidth: `${getProgressBarIndirectWidth(
                  item.share,
                  item.deepDepthShare,
                  item.generalShare,
                  basisGraphWidth,
                  basisPercentGraphWidth,
                )}`,
              }"
              @mouseleave="hoveredIndirectTooltipItem = null"
              @mouseover="hoveredIndirectTooltipItem = index"
            >
              <div class="bold-600 pl-2 pr-2 main-text">
                {{
                  item.deepDepthShare > 0
                    ? formatShare(roundShare(item.deepDepthShare))
                    : item.generalShare > 0
                      ? ''
                      : SMALL_PERCENT_LABEL
                }}
              </div>
            </div>
            <div
              v-tooltip="{
                content: `direct`,
                show: hoveredDirectTooltipItem === index,
                trigger: 'manual',
                boundariesElement: 'body',
              }"
              class="type-entity-bar-direct"
              :style="{
                minWidth: `${getProgressBarDirectWidth(
                  item.share,
                  item.generalShare,
                  item.deepDepthShare,
                  basisGraphWidth,
                  basisPercentGraphWidth,
                )}`,
                maxWidth: `${getProgressBarDirectWidth(
                  item.share,
                  item.generalShare,
                  item.deepDepthShare,
                  basisGraphWidth,
                  basisPercentGraphWidth,
                )}`,
              }"
              @mouseleave="hoveredDirectTooltipItem = null"
              @mouseover="hoveredDirectTooltipItem = index"
            >
              <div class="bold-600 pl-2 pr-2 main-text">
                {{
                  item.generalShare > 0
                    ? formatShare(roundShare(item.generalShare))
                    : item.deepDepthShare > 0
                      ? ''
                      : SMALL_PERCENT_LABEL
                }}
              </div>
            </div>
          </div>
          <div class="gl-scoring-bar-chart-cntrl">
            <GlArrow class="gl-scoring-bar-chart-cntrl-arrow" />
            <GlClose class="gl-scoring-bar-chart-cntrl-close" />
          </div>
        </div>
      </div>
    </transition-group>
  </div>
</template>

<script>
import { mapState } from 'vuex'

// Components
import BadgeInfo from '@/pages/report/components/redesign/risk-score-components/BadgeInfo'

// Utils
import { formatAmount } from '@/utils/format-amount'
import { formatShare, roundShare } from '@/utils/sourcesFormatter'
import { findColorByTypeScore, hex2rgba } from '@/utils/cytoskape-ui-rules'
import { formatByPrice, priceMessage } from '@/utils/format-by-price'
import { calculateRiskLevel } from '@/utils/redesign-report'

//mixins
import deviceWidthMixin from '@/assets/mixins/deviceWidthMixin'

// SVG
import GlArrow from '@/assets/svg/redesign/arrow.svg?inline'
import GlClose from '@/assets/svg/redesign/close.svg?inline'

export default {
  components: {
    BadgeInfo,
    GlArrow,
    GlClose,
  },
  mixins: [deviceWidthMixin],
  props: {
    dataTable: {
      type: Array,
      default: () => [],
    },
    pointerItem: {
      type: Boolean,
      default: false,
    },
    activeBarItem: {
      type: Object,
      default: () => ({}),
    },
    basisPercentGraphWidth: {
      type: Number,
      default: 100,
    },
    basisGraphWidth: {
      type: Number,
      default: 100,
    },
    reverse: {
      type: Boolean,
      default: false,
    },
    separationFactorPoint: {
      type: Number,
      default: 0.1,
    },
    demoForLanding: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      maxShare: 150,
      step: 5,
      lowPoint: 0.0001,
      SMALL_PERCENT_LABEL: '<0.01%',
      hoveredIndirectTooltipItem: null,
      hoveredDirectTooltipItem: null,
      visibleBarName: null,
    }
  },
  computed: {
    ...mapState('analytics', ['coinData']),
    resultData() {
      if (this.visibleBarName) {
        return this.dataTable.filter(item => item.name === this.visibleBarName);
      } else {
        return this.dataTable;
      }
    },
    valueForLanding() {
      return this.demoForLanding ? 200 : 100
    }
  },
  methods: {
    formatAmount,
    roundShare,
    formatShare,
    findColorByTypeScore,
    hex2rgba,
    formatByPrice,
    priceMessage,
    getLabelBarWidth(share, basisWidth = 0, basisPercent = 100) {
      const s = (share / this.maxShare) * this.valueForLanding * basisPercent
      if (this.isMobile) basisWidth = 0
      return `calc(${100 - s}% - ${basisWidth > 0 ? basisWidth + 20 : basisWidth}px)`
    },
    getProgressBarIndirectWidth(
      share,
      itemShare,
      secondShare,
      basisWidth = 0,
      basisPercent = 100,
    ) {
      const s = (itemShare / this.maxShare) * this.valueForLanding * basisPercent
      let widthFix = 18
      if (secondShare > 0 || (!itemShare && !secondShare)) {
        basisWidth =
          itemShare >= this.separationFactorPoint ? 0 : basisWidth / 2
          widthFix = 9
      }
      if (itemShare <= 0 && secondShare > 0) basisWidth = 0

      return `calc(${s}% + ${basisWidth > 0 ? basisWidth + widthFix : basisWidth}px)`
    },
    getProgressBarDirectWidth(
      share,
      itemShare,
      secondShare,
      basisWidth = 0,
      basisPercent = 100,
    ) {
      const s = (itemShare / this.maxShare) * this.valueForLanding * basisPercent
      let widthFix = 18
      if (
        (secondShare > 0 && secondShare < this.separationFactorPoint) ||
        (!itemShare && !secondShare)
      ) {
        basisWidth = basisWidth / 2
        widthFix = 9
      }
      if (itemShare <= 0 && secondShare > 0) basisWidth = 0

      return `calc(${s}% + ${basisWidth > 0 ? basisWidth + widthFix : basisWidth}px)`
    },
    activeBGColor(item, activeItem) {
      console.log(activeItem)
      return item.name === activeItem.name
        ? hex2rgba(findColorByTypeScore(item.score), 0.15)
        : ''
    },
    dataForAmount(item) {
      return this.demoForLanding ?
        this.formatAmount(item.amount, 'clearFamily')
        :
        this.formatAmount(item.amount)
    },
    dataForPrice(item) {
      return this.demoForLanding ?
        this.formatByPrice(this.coinData.family === 'eth' ? (item.amount / 100000000) * 3005 : item.price)
        :
        this.formatByPrice(item.price)
    },
    calculateRiskLevel,
    classesForBarChartRow(value) {
      let classes = [`gl-scoring-bar-chart-row-wrapper`]

      if(this.reverse) {
        classes.push('gl-scoring-bar-chart-reverse')
      }

      if(this.pointerItem) {
        classes.push('gl-scoring-bar-chart-row--animated')
      }

      classes.push(`gl-scoring-bar-chart--${this.calculateRiskLevel(value)}`)

      return classes
    },
    barClick(item) {
      if(!this.demoForLanding) {
        if(this.visibleBarName) {
          this.visibleBarName = null
          this.$emit('scoring-bar-item-click', {})
        } else if(this.visibleBarName != item.name && this.pointerItem) {
          this.visibleBarName = item.name
          this.$emit('scoring-bar-item-click', item)
        }
      }
    },
  },
}
</script>

<style>
.gl-scoring-bar-chart-row-wrapper {
  display: flex;
  width: 100%;
}
.gl-scoring-bar-chart-row {
  display: flex;
  width: 100%;
  min-height: 44px;
  border-bottom: 1px solid var(--pale-blue);
  overflow: hidden;
}

.gl-scoring-bar-chart-bar-wrapper {
  display: flex;
  column-gap: 1px;
  align-items: center;
  width: 100%;
}

.gl-scoring-bar-chart-cell-name {
  display: flex;
  align-items: center;
  font-size: 16px;
  font-weight: 500;
  overflow: hidden;
}

.type-entity-label-wrapper {
  display: flex;
  gap: 8px;
  justify-content: space-between;
}

.type-entity-bar-direct,
.type-entity-bar-indirect {
  display: flex;
  align-items: center;
  font-size: 12px;
  min-height: 28px;
}

.gl-scoring-bar-chart-cell-amount {
  display: flex;
  justify-content: flex-end;
}

.gl-scoring-bar-chart-reverse.gl-scoring-bar-chart-row-wrapper {
  direction: rtl;
}

.gl-scoring-bar-chart-reverse .gl-scoring-bar-chart-cell-name,
.gl-scoring-bar-chart-reverse .gl-scoring-bar-chart-cell-amount-wrapper {
  direction: ltr;
}

.gl-scoring-bar-chart-reverse .gl-scoring-bar-chart-cell-amount {
  justify-content: flex-start;
}

.gl-scoring-bar-chart-reverse .gl-scoring-bar-chart-row:hover .type-entity-bar {
  box-shadow: 4px 1px 0px, 4px -1px 0px, /* Left shadow */ -1px -1px 0px,
    /* Top shadow */ -1px 1px 0px; /* Bottom shadow */
}


/* redesign */
.gl-scoring-bar-chart-row-wrapper:first-child .gl-scoring-bar-chart-row {
  border-top: 1px solid var(--pale-blue);
}
.gl-scoring-bar-chart-row {
  transition: background-color 0.5s ease;
  cursor: pointer;
}
.gl-scoring-bar-chart-row-wrapper .type-entity-bar-indirect {
  background-color: rgba(224, 224, 224, 1);
}
.gl-scoring-bar-chart--low .type-entity-bar-indirect {
  background-color: rgba(112, 172, 63, 0.6);
}
.gl-scoring-bar-chart--low .type-entity-bar-direct {
  background-color: rgba(112, 172, 63, 1);
}
.gl-scoring-bar-chart--medium .type-entity-bar-indirect {
  background-color: rgba(252, 214, 48, 0.6);
}
.gl-scoring-bar-chart--medium .type-entity-bar-direct {
  background-color: rgba(252, 214, 48, 1);
}
.gl-scoring-bar-chart--high .type-entity-bar-indirect {
  background-color: rgba(242, 35, 92, 0.6);
}
.gl-scoring-bar-chart--high .type-entity-bar-direct {
  background-color: rgba(242, 35, 92, 1);
}
.gl-scoring-bar-chart-row--animated:hover .gl-scoring-bar-chart-row {
  background-color: rgba(224, 224, 224, 0.25);
}
.gl-scoring-bar-chart--low:hover .gl-scoring-bar-chart-row,
.gl-scoring-bar-chart--low .gl-scoring-bar-chart-row--active {
  background-color: rgba(112, 172, 63, 0.25);
}
.gl-scoring-bar-chart--medium:hover .gl-scoring-bar-chart-row,
.gl-scoring-bar-chart--medium .gl-scoring-bar-chart-row--active {
  background-color: rgba(252, 214, 48, 0.25);
}
.gl-scoring-bar-chart--high:hover .gl-scoring-bar-chart-row,
.gl-scoring-bar-chart--high .gl-scoring-bar-chart-row--active {
  background-color: rgba(242, 35, 92, 0.25);
}

.gl-scoring-bar-chart-cntrl {
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: center;
  width: 28px;
  height: 28px;
  margin-right: -28px;
  cursor: pointer;
  transition: opacity 0.5s ease, margin-right 0.5s ease;
}
.demo-for-landing .gl-scoring-bar-chart-cntrl {
  display: none;
}
.gl-scoring-bar-chart-cntrl-arrow {
  display: block;
}
.gl-scoring-bar-chart-cntrl-close {
  display: none;
}
.gl-scoring-bar-chart-row--animated:hover .gl-scoring-bar-chart-cntrl,
.gl-scoring-bar-chart-row--active .gl-scoring-bar-chart-cntrl {
  opacity: 1;
  margin-right: 0;
}
.gl-scoring-bar-chart-row--active .gl-scoring-bar-chart-cntrl-arrow {
  display: none;
}
.gl-scoring-bar-chart-row--active .gl-scoring-bar-chart-cntrl-close {
  display: block;
}

.trans-for-bars-enter-active, .trans-for-bars-leave-active {
  transition: opacity 1s;
}
.trans-for-bars-enter, .trans-for-bars-leave-to {
  opacity: 0;
}

@media (max-width: 767px) {
  .gl-scoring-bar-chart-row {
    flex-direction: column;
    gap: 4px;
  }
  .gl-scoring-bar-chart-bar-wrapper {
    width: calc(100% - 100px);
    margin-bottom: 4px;
  }

  .gl-scoring-bar-chart-cell-name {
    font-size: 14px;
  }

  .type-entity-bar-direct,
  .type-entity-bar-indirect {
    font-size: 10px;
    min-height: 22px;
  }


  .gl-scoring-bar-chart-cntrl {
    margin-right: 0;
    transition: none;
  }
  .gl-scoring-bar-chart--low:hover .gl-scoring-bar-chart-row {
    background-color: transparent;
  }
  .gl-scoring-bar-chart--medium:hover .gl-scoring-bar-chart-row {
    background-color: transparent;
  }
  .gl-scoring-bar-chart--high:hover .gl-scoring-bar-chart-row {
    background-color: transparent;
  }
  .gl-scoring-bar-chart--low .gl-scoring-bar-chart-row--active {
    background-color: rgba(112, 172, 63, 0.25) !important;
  }
  .gl-scoring-bar-chart--medium .gl-scoring-bar-chart-row--active {
    background-color: rgba(252, 214, 48, 0.25) !important;
  }
  .gl-scoring-bar-chart--high .gl-scoring-bar-chart-row--active {
    background-color: rgba(242, 35, 92, 0.25) !important;
  }

  .gl-scoring-bar-chart-row {
    padding-top: 8px;
  }
  .gl-scoring-bar-chart-row > .flex {
    display: block;
  }
}
</style>
